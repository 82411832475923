<template>
  <div>
    <div v-for="(m, i) in matches" v-bind:key="i" class="card card-custom card-stretch mb-2">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder">{{ m.name }}</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ m.meta }}</span>
        </h3>
        <div class="mt-2 font-size-h6">{{ date(m.time) }}</div>
      </div>
      <div class="card-body pt-2">
        <!-- Versus mode -->
        <b-row align-v="center" v-if="modevs(m)" class="mb-10">
          <b-col :cols="3" class="text-right h1 display-3 font-weight-bolder pr-10">{{ m.teams[0].score }}</b-col>
          <b-col>
            <b-row align-h="between">
              <div class="symbol symbol-75">
                <div class="symbol-label bg-transparent">
                  <img :src="m.teams[0].img" alt="" class="h-100 w-100" />
                </div>
              </div>
              <b-col cols="auto" class="text-center">
                <div class="h6">{{ m.teams[0].name }}</div>
                <div>vs</div>
                <div class="h6 mt-4">{{ m.teams[1].name }}</div>
              </b-col>
              <div class="symbol symbol-75">
                <div class="symbol-label bg-transparent">
                  <img :src="m.teams[1].img" alt="" class="h-100 w-100" />
                </div>
              </div>
            </b-row>
          </b-col>
          <b-col :cols="3" class="h1 display-3 font-weight-bolder pl-10">{{ m.teams[1].score }}</b-col>
        </b-row>
        <!-- Free for all mode -->
        <div v-if="modeffa(m)" class="mb-5">
          <b-row align-v="center" v-for="(t, i) in m.teams" v-bind:key="i">
            <b-col :cols="3" class="text-right font-size-h4 display-4 font-weight-bolder">
              {{ t.score }}
            </b-col>
            <b-col :cols="1" class="text-center">
              Logo
            </b-col>
            <b-col :cols="6" class="font-size-h4">
              {{ t.name }}
            </b-col>
            <b-col :cols="2">
            </b-col>
          </b-row>
        </div>
        <!-- No teams -->
        <b-row v-if="noteams(m)" class="">
          <b-col :cols="12" class="h4">
            <b-badge v-for="(c, j) in m.noteams" v-bind:key="j" class="mr-4" :variant="chk(c)">{{ c.name }}</b-badge>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: "game_fixture",
  data () {
    return {
      matches: [
        // {
        //   no: 1,
        //   title: 'safdsaf',
        //   meta: 'asdfsadf',
        //   time: '12:20',
        //   teams: [
        //     {
        //       no: 1,
        //       name: '2342342',
        //       score: 6
        //     },
        //     {
        //       no: 2,
        //       name: '435345',
        //       score: 6
        //     }
        //   ],
        //   noteams: [
        //     {
        //       no: 1,
        //       name: '123123',
        //       checked: true
        //     },
        //     {
        //       no: 2,
        //       name: '345345',
        //       checked: false
        //     }
        //   ]
        // },
        // {
        //   no: 2,
        //   title: 'safdsaf',
        //   meta: 'asdfsadf',
        //   time: '12:20',
        //   teams: [
        //     {
        //       no: 1,
        //       name: '2342342',
        //       score: 6
        //     },
        //     {
        //       no: 2,
        //       name: 'greg',
        //       score: 6
        //     },
        //     {
        //       no: 3,
        //       name: 'rfrege',
        //       score: 6
        //     },
        //     {
        //       no: 4,
        //       name: 'reverv',
        //       score: 6
        //     }
        //   ],
        //   noteams: [
        //     {
        //       no: 1,
        //       name: '123123',
        //       checked: true
        //     },
        //     {
        //       no: 2,
        //       name: '345345',
        //       checked: false
        //     }
        //   ]
        // },
        // {
        //   no: 3,
        //   title: 'safderrgsaf',
        //   meta: 'asdfsadf',
        //   time: '12:20',
        //   noteams: [
        //     {
        //       no: 1,
        //       name: '123123',
        //       checked: true
        //     },
        //     {
        //       no: 2,
        //       name: '345345',
        //       checked: false
        //     }
        //   ]
        // },
        // {
        //   no: 4,
        //   title: 'safsfsfdsaf',
        //   meta: 'asdfsadf',
        //   time: '12:20',
        //   teams: [
        //     {
        //       no: 1,
        //       name: '2342342',
        //       score: 6
        //     },
        //     {
        //       no: 2,
        //       name: 'greg',
        //       score: 6
        //     },
        //   ]
        // }
      ]
    }
  },
  methods: {
    modevs(m) {
      return m.teams != null && m.teams.length === 2
    },
    modeffa(m) {
      return m.teams != null && m.teams.length > 2
    },
    chk(c) {
      return c.checked != null && c.checked ? 'success' : 'secondary text-dark-50'
    },
    noteams(m) {
      return m.noteams != null && m.noteams.length > 0
    },
    date(time) {
      return dayjs(time).format('DD/MM/YY HH:mm')
    },
    recvFixture() {
      this.axios.get(`/tournaments/${this.tour}/fixture`).then(
        ({ data }) => {
          this.matches = data.matches
        }
      )
    }
  },
  computed: {
    tour() {
      return this.$route.params.tour
    }
  },
  mounted() {
    this.recvFixture()
  }
}
</script>

<style>

</style>